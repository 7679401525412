// src/Footer.js
import React from "react";
import "./Footer.css";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <p>&copy; 2024 Calculator Impozite. Toate drepturile rezervate.</p>
      </Container>
    </footer>
  );
}

export default Footer;
