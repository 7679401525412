// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./Navigation";
import Home from "./Home";
import Calculator from "./Calculator";
import ContractVanzareCumparare from "./ContractVanzareCumparare";
import Contact from "./Contact";
import DespreNoi from "./DespreNoi";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Footer";

const App = () => {
  return (
    <Router>
      <div>
        <Navigation />
        <hr />
        <Routes>
          <Route path="/impozit-calculator" element={<Calculator />} />
          <Route
            path="/contract-vanzare-cumparare"
            element={<ContractVanzareCumparare />}
          />
          <Route path="/despre-noi" element={<DespreNoi />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
